
@font-face {
    font-family: "Greycliff";
    src: local("Greycliff"),
      url(./assets/greycliff/GreycliffCF-Heavy.woff2) format("woff2");
    font-weight: bold;
  }
  
  @font-face {
    font-family: "Greycliff";
    src: local("Greycliff"),
      url(./assets/greycliff/GreycliffCF-ExtraLight.woff2) format("woff2");
    font-weight: normal;
  }
  
  @font-face {
    font-family: "Hexaframe";
    src: local("Hexaframe"),
      url(./assets/hexaframe/HexaframeCF-Thin.woff2) format("woff2");
    font-weight: normal;
  }
  
  @font-face {
    font-family: "Hexaframe";
    src: local("Hexaframe"),
      url(./assets/hexaframe/HexaframeCF-Bold.woff2) format("woff2");
    font-weight: bold;
  }
  
 body {
  padding-bottom:50vh;
  
 }